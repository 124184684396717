<template>

  <div>

    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <b-card no-body class="p-2">
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Role</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchFilters.roleFilter"
            :options="roleOptions"
            class="w-100"
            label="title"
            :reduce="(option) => option.title"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Vertical</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchFilters.verticalFilter"
            :options="verticalOptions"
            class="w-100"
            label="title"
            :reduce="(option) => option.title"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchFilters.statusFilter"
            :options="statusOptions"
            class="w-100"
            label="title"
            :reduce="(option) => option.title"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0"
        >
        <div class="demo-inline-spacing">
          <b-button variant="outline-primary" @click="applyFilter">
            Filter
          </b-button>
          
          <download-excel :data="userList" :name="filename" v-if="isReadyToDownload">
            <b-button variant="outline-info">
            Download
            </b-button>
          </download-excel>
          <br>
          <span @click="clearFilter" >Clear Filter</span>
        </div>
        </b-col>
        <div class="demo-inline-spacing">
          <span>Filter By Tags: </span>
          <b-badge
            pill
            class="text-capitalize badge-info"
            @click="filterOnProbation">
            On Probation
          </b-badge>
          <b-badge
            pill
            class="text-capitalize badge-info"
            @click="filterProbationEnding">
            Probation Last 15 days
          </b-badge>
          <b-badge
            pill
            class="text-capitalize badge-info"
            @click="clearFilter">
            All Employees
          </b-badge>
          
        </div>
      </b-row>
    </b-card-body>
  </b-card>
  <!-- <b-card no-body class="p-2">
    <b-card-header class="pb-50">
      <h5>
        Filter Tags
      </h5>
    </b-card-header>
    <b-card-body>
      
      
    </b-card-body>
  </b-card> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <span class="text-danger"> {{ error }} </span>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="searchFilters.filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!searchFilters.filter"
                    @click="searchFilters.filter = ''"
                  >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
              <!-- <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="userList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchFilters.filter"
        @filtered="onFiltered"
        :filter-included-fields="filterOn"
      >
      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->

            <b-dropdown-item :to="{ name: 'apps-users-probation', params: { emp_id: data.item.emp_id }}" v-if="!data.item.probation_status">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Probation</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name )"
                :variant="`light-primary`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.emp_id }}</small>
          </b-media>
        </template>
        <template #cell(email)="data">
          <div class="text-nowrap">
            {{ data.item.email }}<br>
            {{ data.item.contact_no }}
          </div>
        </template>
        <template #cell(role)="data">
          <b-badge
            pill
            class="text-capitalize"
            :class="resolveUserRoleVariant(data.item.role)">
            {{ data.item.role === 'TALENT-ADVISOR-SOURCER' ? 'TALENT-ADVISOR' : data.item.role }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            
            class="text-capitalize"
            :class="resolveUserStatusVariant(data.item.status)">
          
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(manager)="data">
          <div class="text-nowrap">
            {{ data.item.manager }} 
          </div>
        </template>
        <template #cell(doj)="data">
          <div class="text-nowrap">
            {{ data.item.doj }} 
          </div>
        </template>
        <template #cell(location)="data">
          <div class="text-nowrap">
            {{ data.item.location }} 
          </div>
        </template>
        <template #cell(dob)="data">
          <div class="text-nowrap">
            {{ data.item.dob }} 
          </div>
        </template>
        <template #cell(separation_date)="data">
          <div class="text-nowrap">
            {{ data.item.separation_date }} 
          </div>
        </template>
        <template #cell(vertical)="data">
          <div class="text-nowrap">
            {{ data.item.vertical }} 
          </div>
        </template>
        <template #cell(probation_days)="data">
          <div class="text-nowrap">
            {{ data.item.probation_days }} Days<br>
            {{ data.item.end_date }} <br>
            {{ data.item.probation_status }} <br>
            {{ data.item.probation_status == undefined || data.item.probation_status == null ? data.item.probation_pending_days : 0 }} Pending Days
          </div>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ perPage * currentPage - perPage}} to {{ totalUsers < 26 ? totalUsers : currentPage * perPage }} of {{ totalUsers }} entries</span>
          </b-col>
          
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import UsersListFilters from './UsersListFilters.vue'
import userService from '@/services/user-service'
import { avatarText } from "@core/utils/filter";
export default {
  data() {
    return {
      userList: null,
      userFullList: null,
      totalUsers: 0,
      tableColumns: [
        { key: 'actions' },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Contact', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'manager', label: 'Manager', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        { key: 'doj', label: 'DOJ', sortable: true },
        { key: 'location', label: 'Location', sortable: true },
        { key: 'dob', label: 'DOB', sortable: true },
        { key: 'separation_date', label: 'LWD', sortable: true },
        { key: 'vertical', label: 'Vertical',sortable: true },
        { key: 'probation_days', label: 'Probation',sortable: true },
      ],
      filterOn: [],
      error: null,
      perPage: 25,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      isSortDirDesc: false,
      sortBy: 'id',
      // filter: null,
      roleOptions: [
        {"title" : "ADMIN"},
        {"title" : "MASTER"},
        {"title" : "HR"},
        {"title" : "MIS"},
        {"title" : "TALENT-ADVISOR-SOURCER"},
        {"title" : "SUPERVISOR"},
        {"title" : "ACCOUNT-MANAGER"}
      ],
      statusOptions: [
        {"title": "ACTIVE"},
        {"title": "INACTIVE"}
      ],
      verticalOptions: [
        { "title": "Service"},
        { "title": "Infra"},        
      ],
      // roleFilter: null,
      // statusFilter: null,
      // verticalFilter: null,
      avatarText,
      isReadyToDownload: true,
      filename: 'Sattva User List.xls',
      searchFilters: {
        roleFilter: null,
        statusFilter: null,
        verticalFilter: null,
        filter: null
      }
    }
  },  
  components: {
    // UsersListFilters,
    // UserListAddNew,
    BCardBody, BCard, BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup, BInputGroupAppend,
    vSelect,
  },
  watch: {
      searchFilters: {
        handler(newFilters) {
          // Convert query parameters and new filters to strings for easy comparison
          const currentQuery = JSON.stringify(this.$route.query);
          const newQuery = JSON.stringify(newFilters);

          // Only navigate if the queries are different
          if (currentQuery !== newQuery) {
            this.$router.push({ query: newFilters }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
              // Ignore NavigationDuplicated error, but rethrow all other errors
            });
          }
        },
        deep: true,
      },
      $route(to) {
        this.searchFilters = { ...this.searchFilters, ...to.query };
      },

    },
  created() {
    console.log('in created')
    if(this.$route.query) {
        this.searchFilters = { 
          statusFilter: this.$route.query.statusFilter,
          roleFilter: this.$route.query.roleFilter,
          verticalFilter: this.$route.query.verticalFilter,
          filter: this.$route.query.filter
        };
    }
    this.applyFilter()
  },
  methods: {
    resolveUserStatusVariant(status) {
      if (status === 'ACTIVE') return 'badge-success'
      if (status === 'INACTIVE') return 'badge-danger'
      return 'badge-secondary'
    },
    resolveUserRoleVariant(role) {
      if (role === 'ADMIN') return 'badge-success'
      if (role === 'MASTER') return 'badge-success'
      if (role === 'HR') return 'badge-warning'
      if (role === 'MIS') return 'badge-info'
      if (role === 'TALENT-ADVISOR-SOURCER') return 'badge-info'
      if (role === 'TALENT-ADVISOR') return 'badge-info'
      if (role === 'ACCOUNT-MANAGER') return 'badge-info'
      if (role === 'SUPERVISOR') return 'badge-warning'
      return 'badge-secondary'
    },
    async getUserList(){
      userService.getUserList().then(res => {
      console.log(res)
        if(res.status === 200) {
          this.userList = res.data.data
          this.userFullList = res.data.data
          this.totalUsers = this.userList.length
        } else if ((res.status === 401 ) || (res.status === 403 )) {
          localStorage.clear();
          this.$router.push({ name: "auth-login" });
        } else if (res.status === 500 ){
          this.error = 'Something went wrong. Please Contact Administrator'
        }
      })
    },
    applyFilter() {
      
      // this.userList = this.userList.filter( user => {
      //   const roleMatches = this.searchFilters.roleFilter === '' || user.role === this.searchFilters.roleFilter;
      //   // console.log(roleMatches)
      //   const statusMatches = this.searchFilters.statusFilter === '' || user.status === this.searchFilters.statusFilter;
      //   // console.log(statusMatches)
      //   const verticalMatches = this.searchFilters.verticalFilter === '' || user.vertical === this.searchFilters.verticalFilter;
      //   // console.log(verticalMatches)
      //   return roleMatches && statusMatches && verticalMatches;
      // })
      
      if(this.searchFilters.statusFilter && this.searchFilters.roleFilter && this.searchFilters.verticalFilter) {
        console.log(' in all')
        this.userList = this.userFullList.filter(item => item.status === this.searchFilters.statusFilter && item.role === this.searchFilters.roleFilter && item.vertical === this.searchFilters.verticalFilter)
        this.totalUsers = this.userList.length
      } else if(this.searchFilters.statusFilter && this.searchFilters.roleFilter && !this.searchFilters.verticalFilter) {
        console.log(' in s r')
        this.userList = this.userFullList.filter(item => item.status === this.searchFilters.statusFilter && item.role === this.searchFilters.roleFilter)  
        this.totalUsers = this.userList.length
      } else if(this.searchFilters.statusFilter && this.searchFilters.verticalFilter && !this.searchFilters.roleFilter) {
        console.log(' in s v')
        this.userList = this.userFullList.filter(item => item.status === this.searchFilters.statusFilter && item.vertical === this.searchFilters.verticalFilter)  
        this.totalUsers = this.userList.length
      } else if(this.searchFilters.verticalFilter && this.searchFilters.roleFilter && !this.searchFilters.statusFilter) {
        console.log(' in r v')
        this.userList = this.userFullList.filter(item => item.vertical === this.searchFilters.verticalFilter && item.role === this.searchFilters.roleFilter)  
        this.totalUsers = this.userList.length
      } else if(this.searchFilters.statusFilter && !this.searchFilters.roleFilter && !this.searchFilters.verticalFilter) {
        console.log(' in s')
        this.userList = this.userFullList.filter(item => item.status === this.searchFilters.statusFilter)  
        this.totalUsers = this.userList.length
      } else if(this.searchFilters.roleFilter && !this.searchFilters.statusFilter && !this.searchFilters.verticalFilter) {
        console.log(' in r')
        this.userList = this.userFullList.filter(item => item.role === this.searchFilters.roleFilter)  
        this.totalUsers = this.userList.length
      } else if(this.searchFilters.verticalFilter && !this.searchFilters.statusFilter && !this.searchFilters.roleFilter) {
        console.log(' in v')
        this.userList = this.userFullList.filter(item => item.vertical === this.searchFilters.verticalFilter)  
        this.totalUsers = this.userList.length
      } else {
        console.log(' in reset')
        this.userList = this.userFullList
        this.totalUsers = this.userList.length
      }
        

        
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalUsers = filteredItems.length
        this.currentPage = 1
    },
    clearFilter() {
      this.searchFilters.roleFilter = null
      this.searchFilters.statusFilter = null
      this.searchFilters.verticalFilter = null
      this.getUserList()
    },
    onProbation(item) {
      const currentDate = new Date()
      const endDate = new Date(item.end_date)
      console.log(currentDate, item.end_date, endDate)
      if(endDate > currentDate) {
        return true
      }
      else{
        return false
      }
    },
    filterOnProbation() {
      //this.userList = this.userFullList
      this.userList = this.userList.filter(item => (item.probation_status == undefined || item.probation_status == null) )
      this.totalUsers = this.userList.length
    },
    filterProbationEnding() {
      //this.userList = this.userFullList
      this.userList = this.userList.filter(item => (item.probation_status == undefined || item.probation_status == null) && (item.probation_pending_days <= 15 && item.probation_pending_days > 0))
      this.totalUsers =  this.userList.length
    }
  },
  async mounted() {
    await this.getUserList()
    
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
./useUsersList_bkp